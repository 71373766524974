import { defineStore } from 'pinia'
import { getProfile as getGirlProfile } from '@/http/girl/profile/profile'
import { getProfile as getConsumerProfile } from '@/http/consumer/profile'
import { girlLogout, consumerLogout } from '@/http/common'
// import { RefreshProfileClass } from '@/helpers/RefreshProfile.class'
import { useAuth } from '@websanova/vue-auth'
import AppConfig from '@/config.class'
import { computed, ref } from 'vue'

export const useCurrentUserStore = defineStore('current-user', () => {
	const auth = useAuth()
	const isAuthorised = ref(false)
	const currentUser = ref(null)
	const userRole = ref(null)

	const setUserRole = (role) => {
		userRole.value = role
	}
	const setCurrentUser = (user) => {
		currentUser.value = user
	}

	const setIsAuthorised = (state) => {
		isAuthorised.value = state
	}

	const currentUserId = computed(() => currentUser.value?.id)
	const isGirl = computed(() => userRole.value === 'girl')
	const isConsumer = computed(() => userRole.value === 'consumer')
	const isGuest = computed(() => AppConfig.guestAccess && !isGirl.value && !isConsumer.value)

	// ToDo внедрить Api Store и переписать логику всех методов с запросами на него
	const fetchCurrentUser = async () => {
		if (userRole.value === 'consumer') {
			const userData = await getConsumerProfile()
			setCurrentUser(userData)
		}
		if (userRole.value === 'girl') {
			const userData = await getGirlProfile()
			setCurrentUser(userData)
		}
	}
	const logoutCurrentUser = async () => {
		const isProduction = process.env.NODE_ENV === 'production'
		const { channels } = window.Echo?.connector || false
		if (channels) {
			window.Echo.connector.channels[`private-${userRole.value}.${currentUserId.value}`].whisper('offline', {
				status: 'offline',
				[`${userRole.value}_id`]: currentUserId.value
			})
			for (const channel in channels) {
				if (Object.hasOwn(channels, channel)) {
					window.Echo.leave(channel)
				}
			}
		}
		try {
			if (isProduction && userRole.value === 'consumer') {
				await consumerLogout()
			}
			if (isProduction && userRole.value === 'girl') {
				await girlLogout()
			}
		} catch (e) {
			throw new Error(e.message)
		} finally {
			// const profileInterval = new RefreshProfileClass()
			// profileInterval.stopInterval()
			await auth.logout({ makeRequest: false, redirect: null })
			isAuthorised.value = false
			currentUser.value = null
			userRole.value = null
			if (AppConfig.guestAccess) {
				auth.user({ role: 'guest' })
				auth.remember(JSON.stringify(auth.user()))
				userRole.value = 'guest'
			}
		}
	}

	const $reset = () => {
		isAuthorised.value = false
		currentUser.value = null
		userRole.value = null
	}

	return {
		currentUserId,
		isAuthorised,
		currentUser,
		userRole,
		isGirl,
		isConsumer,
		isGuest,
		setUserRole,
		setCurrentUser,
		setIsAuthorised,
		fetchCurrentUser,
		logoutCurrentUser,
		$reset
	}
})
