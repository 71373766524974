<template>
	<div class="avatar" :style="avatarSize">
		<div class="avatar-circle placeholder-bg">
			<img v-lazy="imagePath" :src="imagePath" alt="Аватар" />
		</div>
		<div class="online-status" v-show="showOnlineStatus" />
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { useCurrentUserStore } from '@/stores/user'

export default {
	name: 'AvatarCircle',
	props: {
		imagePath: {
			type: String,
			default: ''
		},
		userId: {
			type: [Number, null],
			default: null
		},
		userRole: {
			type: String,
			default: ''
		},
		size: {
			type: Number,
			default: 40
		},
		allowSelfStatus: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState(useCommonStore, {
			getUserOnlineStatus: 'getUserOnlineStatus'
		}),
		...mapState(useCurrentUserStore, {
			currentUserId: 'currentUserId'
		}),
		avatarSize() {
			return {
				width: `${this.size}px`,
				height: `${this.size}px`,
				minHeight: `${this.size}px`,
				minWidth: `${this.size}px`
			}
		},
		showSelfStatus() {
			return this.allowSelfStatus || this.userId !== this.currentUserId
		},
		showOnlineStatus() {
			return (
				this.$config.onlineStatusFeature &&
				this.showSelfStatus &&
				this.userId &&
				this.getUserOnlineStatus(this.userRole, this.userId)
			)
		}
	}
}
</script>

<style lang="scss" scoped>
.avatar {
	position: relative;
	width: 40px;
	height: 40px;

	.online-status {
		position: absolute;
		width: 20%;
		height: 20%;
		border-radius: 100px;
		background-color: #29cc6a;
		bottom: 5%;
		right: 5%;
		z-index: 1;
	}
}

.avatar-circle {
	border-radius: 100%;
	background-size: 100%;
	overflow: hidden;
	width: 100%;
	height: 100%;
	min-width: 30px;
	min-height: 30px;

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
